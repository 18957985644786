import React, { useState } from "react";
import { message } from "antd";
import { useHistory } from "react-router-dom";
import moment from "moment";

function OrdersTableItem(props) {
  console.log([props.billing_address]);

  console.log(moment(props.created_at).format("DD MMM, YYYY"));
  const history = useHistory();
  console.log(
    props.coupon_code,
    props.discount_type,
    props.free_shipping,
    props.reward_value,
    "yooooooooooooooooooooo"
  );
  const b = [props.billing_address];
  const badd =
    props.billing_address !== null &&
    b.map(
      (b) =>
        `City : ${b.bcity}\n` +
        `Country : ${b.bcountry}\n` +
        `Landmark : ${b.blandmark}\n` +
        `Zip : ${b.bzip}\n` +
        `Contact : ${b.contact_no}\n`
    );

  const s = [props.shipping_address];
  const sadd =
    props.shipping_address !== null &&
    s.map(
      (s) =>
        `City : ${s.scity}\n` +
        `Country : ${s.scountry}\n` +
        `Landmark : ${s.slandmark}\n` +
        `Zip : ${s.szip}\n` +
        `Contact : ${s.contact_no}\n`
    );

  const i = props.items.cartItems.map(
    (i) =>
      `Product : ${i.Name}\n` +
      `Price : ${i.price}\n` +
      `Quantity : ${i.quantity}`
  );

  const [showModal, setShowModal] = useState(false);
  const [change, setChange] = useState();
  const [update, setUpdate] = useState();

  const [payment_status, setPayment_status] = useState();
  const [total, setTotal] = useState();
  const [checkout_session, setCheckout_session] = useState();
  const [user_name, setUser_name] = useState();
  const [billing_address, setBilling_address] = useState();
  const [shipping_address, setShipping_address] = useState();
  const [order_status, setOrder_status] = useState();
  const [items, setItems] = useState();
  const [sub_amount, setSub_amount] = useState();
  const [discount_amount, setDiscount_amount] = useState();
  const [refund, setRefund] = useState();
  const [coupon_code, setCoupon_code] = useState();
  const [id, setId] = useState();

  const [discount_type, setDiscount_type] = useState();
  const [free_shipping, setFree_shipping] = useState();
  const [reward_value, setReward_value] = useState();
  const [created_at, setCreated_at] = useState();
  const [updated_at, setUpdated_at] = useState();

  const [uorder_status, setUOrder_status] = useState();

  useState(() => {
    setPayment_status(props.payment_status);
    setTotal(props.total);
    setCheckout_session(props.checkout_session);
    setUser_name(props.username);
    setBilling_address(props.billing_address);
    setShipping_address(props.shipping_address);
    setOrder_status(props.order_status);
    setItems(props.items);
    setSub_amount(props.sub_amount);
    setDiscount_amount(props.discount_amount);
    setRefund(props.refund);
    setCoupon_code(props.coupon_code);
    setId(props.id);
    setFree_shipping(props.free_shipping);
    setReward_value(props.reward_value);
    setDiscount_type(props.discount_type);
    setCreated_at(moment(props.created_at).format("DD MMM, YYYY"));
    setUpdated_at(moment(props.updated_at).format("DD MMM, YYYY"));
    setUOrder_status(props.order_status);
  }, []);

  const UpdateOrder = (id) => {
    return (e) => {
      var axios = require("axios");

      var data = JSON.stringify({
        order_status: uorder_status ? uorder_status : order_status,
      });

      var config = {
        method: "put",
        url: `https://adminapi.youjifresh.com/allorders/${id}`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          message.success("Order Status Updated");
          setChange(false);
          setShowModal(false);
          setTimeout(() => {
            window.location.reload();
          }, 500);
        })
        .catch(function (error) {
          console.log(error);
          message.error(error.response.data.message);
        });
    };
  };

  const cancel = () => {
    setShowModal(false);

    setPayment_status(props.payment_status);
    setTotal(props.total);
    setCheckout_session(props.checkout_session);
    setUser_name(props.username);
    setBilling_address(props.billing_address);
    setShipping_address(props.shipping_address);
    setOrder_status(props.order_status);
    setItems(props.items);
    setSub_amount(props.sub_amount);
    setDiscount_amount(props.discount_amount);
    setRefund(props.refund);
    setCoupon_code(props.coupon_code);
    

    setUOrder_status(props.order_status);
    if (change === true) {
      window.alert("All the changes will be discarded");

      setPayment_status(props.payment_status);
      setTotal(props.total);
      setCheckout_session(props.checkout_session);
      setUser_name(props.username);
      setBilling_address(props.billing_address);
      setShipping_address(props.shipping_address);
      setOrder_status(props.order_status);
      setItems(props.items);
      setSub_amount(props.sub_amount);
      setDiscount_amount(props.discount_amount);
      setRefund(props.refund);
      setCoupon_code(props.coupon_code);

      setUOrder_status(props.order_status);

      setShowModal(false);
      message.warning("Changes discarded");

      setChange(false);
    }
    setUpdate(false);
  };

  return (
    <>
      <tbody className="text-sm">
        {/* Row */}
        <tr>
          {/* <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
          <div className="flex items-center">
            <label className="inline-flex">
              <span className="sr-only">Select</span>
              <input id={props.id} className="form-checkbox" type="checkbox" onChange={props.handleClick} checked={props.isChecked} />
            </label>
          </div>
        </td> */}
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="flex items-center text-gray-800">
              <div className="font-medium text-light-blue-500">{props.id}</div>
            </div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="text-left font-medium text-yellow-500">
              {props.username}
            </div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div>{props.payment_status}</div>
          </td>
          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap">
            <div className="font-medium text-gray-800">{props.total}</div>
          </td>

          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
            <div className="text-left font-medium text-yellow-500">
              {props.order_status}
            </div>
          </td>

          <td className="px-2 first:pl-5 last:pr-5 py-3 whitespace-nowrap w-px">
            <button
              onClick={() => {
                history.push("/updateOrder", {
                  payment_status,
                  total,
                  checkout_session,
                  user_name,
                  billing_address,
                  shipping_address,
                  order_status,
                  sub_amount,
                  discount_amount,
                  refund,
                  coupon_code,
                  id,
                  items,
                  discount_type,
                  free_shipping,
                  reward_value,
                  created_at,
                  updated_at
                });
              }}
              className="btn bg-yellow-500 text-white p-1"
            >
              View
            </button>
          </td>
        </tr>
      </tbody>
    </>
  );
}

export default OrdersTableItem;
