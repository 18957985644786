import React, { useState, useEffect } from "react";
import Header from "../Header";
import Sidebar from "../Sidebar";
import { useHistory } from "react-router-dom";
import { message } from "antd";
import OrderHeader from "./OrderHeader";
import OrderItemTable from "./OrderItemTable";
import Item from "antd/lib/list/Item";

function UpdateOrder(props) {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [orderitems, setOrderItem] = useState(
    props.location.state.items.cartItems
  );
  console.log(props.location.state.coupon_code);
  console.log(props);
  const history = useHistory();

  // "select".on("change", function () {
  //   if (this.find("option:selected").text() == "Cancel")
  //     "#btnSubmit".attr("disabled", true);
  //   else "#btnSubmit".attr("disabled", false);
  // });

  const b = [props.location.state.billing_address];
  const badd =
    props.location.state.billing_address !== null &&
    b.map(
      (b) =>
        `City : ${b.bcity}\n` +
        `Country : ${b.bcountry}\n` +
        `Landmark : ${b.blandmark}\n` +
        `Zip : ${b.bzip}\n` +
        `Contact : ${b.contact_no}\n`
    );

  const s = [props.location.state.shipping_address];
  const sadd =
    props.location.state.shipping_address !== null &&
    s.map(
      (s) =>
        `City : ${s.scity}\n` +
        `Country : ${s.scountry}\n` +
        `Landmark : ${s.slandmark}\n` +
        `Zip : ${s.szip}\n`
    );

  const i =
    props.location.state.items.cartItems !== null &&
    props.location.state.items.cartItems.map(
      (i) =>
        `Product : ${i.Name}\n` +
        `Price : ${i.price}\n` +
        `Quantity : ${i.quantity}\n` +
        `remarks:${i.remarks}\n`
    );

  const [showModal, setShowModal] = useState(false);
  const [change, setChange] = useState();
  const [update, setUpdate] = useState();

  const [payment_status, setPayment_status] = useState();
  const [total, setTotal] = useState();
  const [checkout_session, setCheckout_session] = useState();
  const [user_name, setUser_name] = useState();
  const [billing_address, setBilling_address] = useState();
  const [shipping_address, setShipping_address] = useState();
  const [order_status, setOrder_status] = useState();
  const [items, setItems] = useState();
  const [sub_amount, setSub_amount] = useState();
  const [discount_amount, setDiscount_amount] = useState();
  const [refund, setRefund] = useState();
  const [coupon_code, setcoupon_code] = useState();
  const [id, setId] = useState();

  const [uorder_status, setUOrder_status] = useState();
  const [discount_type, setDiscount_type] = useState();
  const [free_shipping, setFree_shipping] = useState();
  const [reward_value, setReward_value] = useState();
  const [created_at, setCreated_at] = useState();
  const [updated_at, setUpdated_at] = useState();
  const [disable, setDisable] = useState(false);

  useState(() => {
    setPayment_status(props.location.state.payment_status);
    setTotal(props.location.state.total);
    setCheckout_session(props.location.state.checkout_session);
    setUser_name(props.location.state.user_name);
    setBilling_address(props.location.state.billing_address);
    setShipping_address(props.location.state.shipping_address);
    setOrder_status(props.location.state.order_status);
    setItems(props.location.state.items);
    setSub_amount(props.location.state.sub_amount);
    setDiscount_amount(props.location.state.discount_amount);
    setRefund(props.location.state.refund);
    setcoupon_code(props.location.state.coupon_code);
    setId(props.location.state.id);
    setUOrder_status(props.location.state.order_status);
    setDiscount_type(props.location.state.discount_type);
    setFree_shipping(props.location.state.free_shipping);
    setReward_value(props.location.state.reward_value);
    setCreated_at(props.location.state.created_at);
    setUpdated_at(props.location.state.updated_at);
  }, []);
  console.log(discount_type, free_shipping, reward_value, coupon_code, "this");
  const UpdateOrder = (id) => {
    return (e) => {
      var axios = require("axios");

      var data = JSON.stringify({
        order_status: uorder_status ? uorder_status : order_status,
      });

      var config = {
        method: "put",
        url: `https://adminapi.youjifresh.com/orders/${id}`,
        headers: {
          Authorization: "Bearer " + sessionStorage.getItem("youji_token"),
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios(config)
        .then(function (response) {
          console.log(JSON.stringify(response.data));
          message.success("Order Status Updated");
          setChange(false);
          setShowModal(false);
          history.push("/orders");
        })
        .catch(function (error) {
          console.log(error);
          message.error(error.response.data.message);
        });
    };
  };

  const cancel = () => {
    setShowModal(false);

    setPayment_status(props.location.state.payment_status);
    setTotal(props.location.state.total);
    setCheckout_session(props.location.state.checkout_session);
    setUser_name(props.location.state.user_name);
    setBilling_address(props.location.state.billing_address);
    setShipping_address(props.location.state.shipping_address);
    setOrder_status(props.location.state.order_status);
    setItems(props.location.state.items);
    setSub_amount(props.location.state.sub_amount);
    setDiscount_amount(props.location.state.discount_amount);
    setRefund(props.location.state.refund);
    setcoupon_code(props.location.state.coupon_code);

    setUOrder_status(props.location.state.order_status);
    if (change === true) {
      window.alert("All the changes will be discarded");

      setPayment_status(props.location.state.payment_status);
      setTotal(props.location.state.total);
      setCheckout_session(props.location.state.checkout_session);
      setUser_name(props.location.state.user_name);
      setBilling_address(props.location.state.billing_address);
      setShipping_address(props.location.state.shipping_address);
      setOrder_status(props.location.state.order_status);
      setItems(props.location.state.items);
      setSub_amount(props.location.state.sub_amount);
      setDiscount_amount(props.location.state.discount_amount);
      setRefund(props.location.state.refund);
      setcoupon_code(props.location.state.coupon_code);

      setUOrder_status(props.location.state.order_status);

      setShowModal(false);
      message.warning("Changes discarded");

      setChange(false);
    }
    setUpdate(false);
  };

  const handlecancel = () => {
    setDisable(true);
  };

  return (
    <>
      <div className="flex h-screen overflow-hidden">
        {/* Sidebar */}
        <Sidebar sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />

        {/* Content area */}
        <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
          {/*  Site header */}
          <OrderHeader
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />

          <main>
            <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
              {/* Page header */}
              <div className="sm:flex sm:justify-between sm:items-center mb-8">
                {/* Left: Title */}
                <div className="mb-4 sm:mb-0">
                  <h1 className="text-2xl px-6 md:text-3xl text-gray-800 font-bold">
                    Update Order
                  </h1>
                </div>

                {/* Right: Actions */}
                <div className="grid grid-flow-col sm:auto-cols-max justify-start sm:justify-end gap-2">
                  {/* Delete button */}
                  {/* <DeleteButton selectedItems={selectedItems} /> */}
                  {/* Dropdown */}
                  {/* <DateSelect /> */}
                  {/* Filter button */}

                  {/* Add customer button */}
                </div>
              </div>

              {/* Table */}
              <div className="relative p-6 flex-auto">
                <form id="form_add_prod">
                  <div class="flex flex-wrap -mx-2 mb-2">
                    <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_pay"
                      >
                        PAYMENT MODE
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_pay"
                        type="text"
                        value={payment_status}
                        placeholder="payment_status"
                        disabled
                      />
                    </div>

                    <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_total"
                      >
                        TOTAL
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_total"
                        type="text"
                        value={total}
                        placeholder="payment_status"
                        disabled
                      />
                    </div>

                    <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_price"
                      >
                        SUB AMOUNT
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_price"
                        type="number"
                        value={sub_amount}
                        placeholder="sub_amount"
                        disabled
                      />
                    </div>

                    <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_price"
                      >
                        DISCOUNT AMOUNT
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_price"
                        type="number"
                        value={discount_amount}
                        placeholder="discount_amount"
                        disabled
                      />
                    </div>
                    <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_c"
                      >
                        Username
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_c"
                        type="text"
                        value={user_name}
                        placeholder="user_name"
                        disabled
                      />
                    </div>

                    <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_coupon_code"
                      >
                        COUPON CODE
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_coupon_code"
                        type="text"
                        value={coupon_code}
                        placeholder="coupon_code"
                        disabled
                      />
                    </div>

                    <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__coupon_active"
                      >
                        ORDER STATUS
                      </label>
                      <div className="relative">
                        <select
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__product_category"
                          value={uorder_status ? uorder_status : order_status}
                          onChange={(e) => {
                            setUpdate(true);
                            if (
                              (order_status || uorder_status) !== e.target.value
                            ) {
                              setChange(true);
                            }
                            setOrder_status();
                            setUOrder_status(e.target.value);
                            console.log(e.target.value);
                          }}
                        >
                          <option value={order_status} selected>
                            {order_status === "Processed"
                              ? "Processed"
                              : order_status === "Delivered"
                              ? "Delivered"
                              : order_status === "Refunded"
                              ? "Refunded"
                              : // : order_status === "Cancelled"
                                // ? "Cancelled"
                                "Select"}
                          </option>

                          <option
                            value="Processed"
                            hidden={order_status === "Processed"}
                          >
                            Processed
                          </option>

                          <option
                            value="Delivered"
                            hidden={order_status === "Delivered"}
                          >
                            Delivered
                          </option>
                          <option
                            value="Refunded"
                            hidden={order_status === "Refunded"}
                          >
                            Refunded
                          </option>
                          {/*
                          <option
                            value="Cancelled"
                            hidden={order_status === "Cancelled"}
                          >
                            Cancel
                          </option> */}
                        </select>
                      </div>
                    </div>
                    <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_c"
                      >
                        CHECKOUT SESSION
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 mb-3 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_c"
                        type="text"
                        value={checkout_session}
                        placeholder="checkout_session"
                        disabled
                      />
                    </div>
                    <div class="w-full md:w-1/3 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__free_shipping"
                      >
                        Free Shipping
                      </label>
                      <div className="relative">
                        <select
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__free_shipping"
                          value={free_shipping}
                        >
                          <option value={free_shipping} selected>
                            {free_shipping === true
                              ? "True"
                              : free_shipping === false
                              ? "False"
                              : null}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="w-full md:w-1/3 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__discount_type"
                      >
                        Discount type
                      </label>
                      <div className="relative">
                        <select
                          class="block appearance-none w-full bg-gray-200 border border-gray-200 text-gray-700 py-3 px-3 pr-8 rounded leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                          id="form__discount_type"
                          value={discount_type}
                        >
                          <option value={discount_type} selected>
                            {discount_type === "Reward"
                              ? "Reward"
                              : discount_type === "Coupon"
                              ? "Coupon"
                              : null}
                          </option>
                        </select>
                      </div>
                    </div>
                    <div class="w-full md:w-1/3 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_reward"
                      >
                        Reward Value
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_reward"
                        type="text"
                        value={reward_value}
                        placeholder="reward value"
                        disabled
                      />
                    </div>
                    <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__order_date"
                      >
                        Order Date
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__order_date"
                        type="text"
                        value={created_at}
                        placeholder="Order Date"
                        disabled
                      />
                    </div>
                    <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__order_update_date"
                      >
                        Order Update Date
                      </label>
                      <input
                        class="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__order_update_date"
                        type="text"
                        value={updated_at}
                        placeholder="Order Update date"
                        disabled
                      />
                    </div>

                    <div class="w-full md:w-1/2 px-2 mb-6 md:mb-0 mt-4">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_description"
                      >
                        BILLING ADDRESS
                      </label>
                      <textarea
                        class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_description"
                        type="textarea"
                        value={billing_address !== null ? badd : ""}
                        placeholder="description of the product"
                        disabled
                        rows="8"
                      />
                    </div>

                    <div class="w-full md:w-1/2 p-2 mb-6 md:mb-0 mt-4">
                      <label
                        class="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2"
                        for="form__product_description"
                      >
                        SHIPPING ADDRESS
                      </label>
                      <textarea
                        class="appearance-none block text-clip resize-y overflow-hidden w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 mb-3 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                        id="form__product_description"
                        type="textarea"
                        value={shipping_address !== null ? sadd : ""}
                        placeholder="description of the product"
                        disabled
                        rows="8"
                      />
                    </div>

                    {/* </div> */}
                  </div>
                </form>
                <OrderItemTable MOCK_DATA={orderitems} />
              </div>

              <div className="flex items-center  p-6 border-t border-solid border-blueGray-200 rounded-b">
                <div className="justify-items-end ">
                  <button
                    className="btn bg-red-500  hover:bg-red-600 text-white mr-2"
                    onClick={cancel}
                  >
                    <span className="hidden xs:block">Cancel</span>
                  </button>
                  {update ? (
                    <button
                      className="btn bg-yellow-500 hover:bg-yellow-600 text-white"
                      onClick={UpdateOrder(id)}
                      disabled={disable}
                    >
                      <span className="hidden xs:block">Update</span>
                    </button>
                  ) : (
                    <button
                      className="btn bg-yellow-500 opacity-50 text-white"
                      disabled
                    >
                      <span className="hidden xs:block">Update</span>
                    </button>
                  )}
                </div>
              </div>

              {/* Pagination */}
            </div>
          </main>
        </div>
      </div>
    </>
  );
}

export default UpdateOrder;
